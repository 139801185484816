@import "./variables.scss";

.hide-cookie-bar {
  footer {
    margin-bottom: 0;
  }
  .cookies_bar {
    display: none;
  }
}

// On desktop position the ISI above the cookie bar.
// @media screen and (min-width: $md) {
//   body.isi--minimized {
//     .show-cookie-bar {
//       .cookies_bar {
//         bottom: 0;
//       }
//     }
//   }
//   .show-cookie-bar {
//     .cookies_bar {
//       bottom: 0;
//       &.isi-hidden {
//         bottom: 0 !important;
//       }
//     }
//   }
//   .hide-cookie-bar {
//     #mini-isi {
//       bottom: 0;
//     }
//   }
// }
.cookies_bar_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 97;
  background: rgba(78, 77, 77, 0.5);
}
.cookies_bar {
  position: relative;
  z-index: 1000;
  .container {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    max-width: 816px;
    padding: 40px;
    z-index: 98;
    background-color: $white;
    transform: translate(-50%, -50%);
    @media (max-width: $lg) {
      width: 90%;
      padding: 24px;
      top: 150px;
      transform: translateX(-50%);
      .row {
        margin: 0;
        flex-direction: column;
        .outer-cookie-content {
          width: 100%;
          max-width: inherit;
          padding: 0;
          .col-md-10 {
            width: 100%;
            padding: 0;
            max-width: inherit;
          }
        }
        .accept-btn {
          width: 100%;
          max-width: inherit;
          margin-top: 15px;
        }
      }
    }
    @media (max-width: $md) {
      height: inherit;
    }
    @media (max-width: $sm) {
      .row {
        a {
          display: block;
          br {
            display: none;
          }
        }
        .accept-btn button {
          float: none;
          width: 100%;
          display: block;
          &:hover,
          &:active,
          &:focus {
            background: $Tangerine !important;
            color: #fff !important;
          }
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0;
      color: $SmokeGrey;
    }
    a {
      font-weight: 500;
      color: $Tangerine;
      text-decoration: underline;
      display: block;
    }
    .space-between {
      justify-content: space-between;
    }
    .col-md-2 {
      @media (max-width: $lg) {
        padding: 0;
      }
      @media (max-width: 767px) {
        padding: 0 15px 15px;
      }
    }
    .button-wrapper {
      width: 100%;
      padding: 0;
      text-align: center;
      margin-top: 25px;
      // @media (max-width: $md) {
      //   flex: 0 0 20%;
      //   max-width: 20%;
      // }
      // @media (max-width: $sm) {
      //   flex: 0 0 30%;
      //   max-width: 30%;
      // }
      #rcc-decline-button {
        position: absolute;
        top: 2px;
        right: 10px;
        background: none;
        border: none;
        color: $NavyBlue;
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }
    }
    .outer-cookie-content {
      // @media (max-width: $md) {
      //   flex: 0 0 80%;
      //   max-width: 80%;
      //   padding: 0;
      // }
      // @media (max-width: $sm) {
      //   flex: 0 0 70%;
      //   max-width: 70%;
      // }
    }
    .btn-outline-primary {
      font-size: 16px;
      font-family: $BaseFontBold;
      color: #fff;
      text-transform: uppercase;
      padding: 14px 35px 10px 35px;
      line-height: 1.5;
      border-radius: 30px;
      border: 2px solid #fff;
      background: $Tangerine;
      max-width: 315px;
      width: 100%;
      &:hover,
      &:focus {
        background-color: #fff;
        border-color: $Tangerine;
        color: $Tangerine;
        outline: none;
        box-shadow: none;
      }
    }

    br {
      &.break-420 {
        display: none;
        @media (max-width: 420px) {
          display: block;
        }
      }
      &.break-410 {
        display: none;
        @media (max-width: 410px) {
          display: block;
        }
      }
    }
    // &[data-is-cld="-1"] {
    //   max-width: calc(100% - #{$sidebarISIWidth});
    //   padding-right: 20px;
    //   @media screen and (max-width: $sidebarISICollapse) {
    //     max-width: inherit;
    //   }
    //   @media screen and (max-width: $sm) {
    //     padding-right: 0;
    //   }
    // }
  }
}
