@import "../../css/variables.scss";

.resource-section {
  .dark-blue-bg {
    margin-bottom: 40px;
  }

  &#more-support {
    .resource-section--header {
      padding-top: 0;

      @media screen and (min-width: $md) {
        padding-bottom: 10px;
      }

      @media screen and (max-width: $md) {
        img {
          margin-top: 0;
        }
      }
    }
  }
}

.resource-section--resource {
  margin: 0;
  padding: 40px 0 30px 0;

  &:nth-child(even) {
    background: $CloudBlue;
  }
}

.resource-section--link {
  display: inline-block;
  text-transform: uppercase;
  font-family: $BaseFontBold;
}

.resource-image {
  max-width: 100px;
}

.app-link {
  display: inline-block;
  margin-right: 0;
  padding: 10px;

  img {
    width: 100%;
    max-width: 150px;
  }

  +p {
    margin-top: 27px;
  }

  @media screen and (max-width: $md) {
    img {
      max-width: 150px;
    }

    +p {
      margin-top: 10px;
    }
  }
}

// header
.resource-section--header {
  padding-top: 40px;
  padding-bottom: 30px;

  @media (max-width: $sm) {
    padding-top: 0;
  }

  h2 {
    font-size: 25px;
  }

  img {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    display: block;

    @media screen and (max-width: $md) {
      margin-top: 40px;
    }
  }
}

// patient resources
#patient-resources {
  .resource-section--header {
    img {
      max-width: 360px;
    }
  }
}

// got questions
#got-questions {
  .resource-section--header {
    img {
      max-width: 360px;
    }
  }
}
