@import "./variables.scss";

.thank-you {
	picture {
		display: block;
		margin: 0 auto;
		max-width: 450px;

		img {
			width: 100%;
		}
	}
}
