.unsubscribe {
  .content-set {
    max-width: 700px;
    margin: auto;
  }
  .unsub-footer-text {
    font-size: 12px;
  }
}
#unsubscribe-form {
  width: inherit;
  max-width: 700px;
  margin: auto;
  .form-check {
    margin-bottom: 20px;
    .form-check-label {
      span {
        width: inherit;
      }
    }
  }
}
