// Import Fonts
@import "./Fonts.css";

//=== Import Styles ===//
@import "./variables.scss";
@import "./Buttons.scss";
@import "./MenuBtn.scss";

.inner-content-wrap.sidebar-isi-push .container {
  max-width: 880px;

  @media screen and (max-width: $sidebarISICollapse) {
    max-width: inherit;
  }
}

.inner-content-wrap {
  a:not(.btn):not(.dropdown-item) {
    text-decoration: underline;

    &:hover {
      color: $Tangerine !important;
    }
  }
}

// Base Style Start //
html {
  max-width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;

  body {
    font-family: $BaseFont;
    font-size: 18px;
    line-height: 27px;
    padding: 0;
    margin: 0;
    color: $NavyBlue;
    overflow-x: hidden;
    max-width: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  .ITPHome {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .Home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .main_content_wrap {
    flex: 1 0 auto;
  }

  .row {
    box-sizing: border-box;
  }

  .inner-content-wrap {
    margin: 146px 0 0;

    @media (max-width: $xl) {
      margin: 80px 0 0;
    }

    @media (max-width: $sm) {
      margin: 70px 0 0;
    }

    &.sidebar-isi-push {
      max-width: calc(100% - #{$sidebarISIWidth});

      @media screen and (max-width: $sidebarISICollapse) {
        max-width: inherit;
        margin-top: 132px;
      }
    }
  }

  .CLDHome,
  .notfound {
    .inner-content-wrap {
      margin: 140px 0 0;

      @media (max-width: $xl) {
        margin: 80px 0 0;
      }

      @media (max-width: $sm) {
        margin: 70px 0 0;
      }
    }
  }

  .Home {
    .inner-content-wrap {
      margin: 40px 0 0;

      @media (max-width: $xl) {
        margin: 80px 0 0;
      }

      @media (max-width: $sm) {
        margin: 70px 0 0;
      }
    }
  }
}

body {
  &.no-scroll {
    overflow-y: hidden;

    .App {
      position: relative;
    }
  }

  &.nav-open {
    overflow-y: hidden;
  }

  &.isi--minimized {
    .scroll_btn {
      bottom: 60px;
    }

    .show-cookie-bar {
      .scroll_btn {
        bottom: 124px;

        @media (max-width: 768px) {
          bottom: 161px;
        }

        @media (max-width: 610px) {
          bottom: 183px;
        }

        @media (max-width: 501px) {
          bottom: 205px;
        }

        @media (max-width: 384px) {
          bottom: 227px;
        }
      }
    }
  }

  .App {
    text-align: left;
  }

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      padding-left: 20px;
      font-weight: 500;
      line-height: 1.35;

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #1b4677;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }

  .container {
    max-width: 1270px;

    @media (max-width: $xl) {
      padding: 0 32px;
    }
  }

  a {
    font-size: 16px;
    font-weight: 500;
    color: $Tangerine;
  }

  h1 {
    font-size: 90px;
    font-weight: 500;
    font-family: $HeaderFont;
    line-height: 1;

    @media (max-width: $lg) {
      font-size: 60px;
    }
  }

  h2 {
    font-size: 25px;
    font-family: $BaseFontBold;
    line-height: 33px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: $BaseFontBold;

    &.smaller-width {
      max-width: 75%;

      @media (max-width: $sm) {
        max-width: 90%;
      }
    }

    &.h3-header {
      font-family: $BaseFontBold;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 27px;
  }

  p {
    a {
      font-size: inherit;
    }

    &.footnote:first-letter {
      margin-left: -7px;
    }
  }

  br {
    @media (max-width: $lg) {
      &.no-show-mobile {
        display: none;
      }
    }
  }

  sup {
    top: -0.75em;
    font-size: 55%;
  }

  strong {
    font-family: "FuturaBold";
    font-weight: 500;
  }

  .size-16 {
    font-size: 16px;
  }

  .mobile-unbold {
    font-family: $BaseFont;
  }

  .no-break {
    white-space: nowrap;
  }

  .no-break-mobile {
    @media (max-width: $lg) {
      white-space: nowrap;
    }
  }

  .no-break-desktop-mobile {
    white-space: nowrap;
  }

  .break-on-desktop-only {
    display: block;

    @media (max-width: $md) {
      display: inline;
    }
  }

  .break-on-mobile-only {
    display: inline;

    @media (max-width: $md) {
      display: block;
    }
  }

  .break-on-mobile-400 {
    display: none;

    @media (max-width: 400px) {
      display: block;
    }
  }

  br.modal-br {
    display: none;

    @media (max-width: 400px) {
      display: block;
    }
  }

  .border-top {
    border-top: 1px solid #707070 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #707070 !important;
  }

  .italic {
    font-style: italic;
  }

  .cond-md {
    font-family: "FuturaCond-Md";
  }

  .lightBlue {
    color: $LightBlue;
  }

  .text-blue {
    color: $NavyBlue;
  }

  .no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .padding-top {
    padding-top: 40px;
  }

  .center {
    text-align: center;
  }

  .bg-white {
    background-color: $white !important;
  }

  .text-underline {
    text-decoration: underline;
  }

  .no-underline {
    text-decoration: none;
  }

  .underline-mobile {
    @media (max-width: $md) {
      text-decoration: underline;
    }
  }

  .text-white {
    color: $white;
  }

  .text-grey {
    color: $SmokeGrey;
  }

  .text-orange {
    color: $Tangerine;
  }

  .text-red {
    color: $CherryRed;
  }

  .text-magenta {
    color: #F4149E;
  }

  .text-18px {
    font-size: 18px;
  }

  .text-bold {
    font-family: $BaseFontBold;
  }

  .unbold {
    font-family: $BaseFont !important;
  }

  .inline-link {
    font-size: inherit;
    text-decoration: underline;
  }

  .inline-link-no-dec {
    font-size: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  img.width-100 {
    width: 100%;
  }

  .no-decoration {
    text-decoration: none !important;
  }

  .no-break-415 {
    white-space: normal;

    @media (max-width: 415px) {
      white-space: nowrap;
    }
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  margin: auto;
}

// Offset used for scrolling to content.
.scroll-to-section {
  scroll-margin-top: 150px;

  @media (max-width: $md) {
    scroll-margin-top: 100px;
  }
}

.recruitment-banner {
  background-color: #144778;
  overflow: hidden;
  transition: max-height 200ms ease;
  max-height: 180px;

  &.slideUp {
    max-height: 0;
  }

  .container {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: $md) {
      padding-left: 22px;
      padding-right: 22px;
    }

    @media (max-width: $sm) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .bannerClose {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;

    img {
      width: 17px;
      height: auto;
    }
  }

  h2 {
    font-family: "simpleaf";
    font-size: 80px;
    color: #fff;
    margin: 0;

    @media (max-width: $xl) {
      font-size: 60px;
    }

    @media (max-width: $lg) {
      font-size: 50px;
    }

    @media (max-width: $md) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: $sm) {
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      width: 100%;
    }
  }

  img {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 122px;
    height: auto !important;

    @media (max-width: $md) {
      max-width: 90px;
      margin-left: 15px;
      margin-right: 15px;
    }

    @media (max-width: $sm) {
      max-width: 63px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .btn {
    min-width: auto;

    @media (max-width: $md) {
      font-size: 12px;
    }

    @media (max-width: $sm) {
      font-size: 8px;
      padding: 7px 12px;
    }
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;

  &:hover {
    width: 256px !important;
  }
}

.cloud-blue-bg {
  background-color: #f0f6ff;

  &.dropshadow {
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  }
}
