// Font Family
$BaseFont: 'FuturaMedium';
$BaseFontBold: 'FuturaBold';
$HeaderFont: 'simpleaf';

// Colors
$NavyBlue: #1B4677;
$Tangerine: #E05A44;
$PeachyKeen: #FCE7E3;
$CherryRed: #CC203C;
$white: #FFFFFF;
$LightBlue: #6A94D0;
$SmokeGrey: #4D4D4D;
$CloudBlue: #F0F6FF;

// Breakpoints
$xl: 1199px;
$lg: 991px;
$md: 768px;
$sm: 575px;
$hero_max: 1440px;

$sidebarISIWidth: 360px;
$sidebarISICollapse: 1280px;
