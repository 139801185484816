@import "./variables.scss";

body {
  .btn {
    &.smaller-padding {
      padding: 14px 15px 10px 15px;
    }
  }

  .btn-primary {
    font-size: 16px;
    font-family: $BaseFontBold;
    text-transform: uppercase;
    min-width: 300px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 30px;
    background-color: $Tangerine;
    border: 2px solid transparent;
    max-width: 470px;
    padding: 12px 35px 12px 35px;
    letter-spacing: 1px;
    line-height: 1.1em;

    &:hover,
    &:active,
    &:focus {
      color: $Tangerine !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: #fff !important;
      border-color: $Tangerine !important;
    }

    &.larger {
      @media (min-width: $sm) {
        min-width: 400px;
      }
    }

    &.white-border {
      border: 2px solid #fff;

      &:hover,
      &:active,
      &:focus {
        color: $Tangerine;
        outline: none !important;
        box-shadow: none !important;
        background-color: #fff !important;
        border-color: $Tangerine;

        @media (max-width: $sm) {
          color: #fff !important;
          background-color: $Tangerine !important;
          border-color: #fff !important;
        }
      }
    }

    @media (max-width: $sm) {
      min-width: inherit;
      max-width: inherit;
      width: 100%;
    }

    &.btn-transparent {
      background: transparent;
      color: $Tangerine;
      border-width: 1px;
      border-color: $Tangerine;

      &.btn-arrow {
        &:after {
          content: url(/images/arrow-icon.svg);
        }
      }

      &:active,
      &:focus,
      &:hover {
        background-color: #fff;
        outline: 2px solid #e05a44 !important;
        outline-offset: -2px;
      }
    }

    &.btn-white {
      border-color: #fff;
      color: #fff;

      &.btn-arrow {
        &:after {
          content: url(/images/arrow-icon-white.svg);
        }

        &:hover:after {
          content: url(/images/arrow-icon.svg);
        }
      }
    }

    &.btn-arrow {
      position: relative;
      padding: 12px 30px 10px 12px;
      font-family: "FuturaHeavy";

      &:not(.btn-transparent) {
        &:after {
          content: url(/images/arrow-icon-white.svg);
        }

        &:active:after,
        &:focus:after,
        &:hover:after {
          content: url(/images/arrow-icon.svg);
        }
      }

      &:after {
        position: absolute;
        right: 12px;
        top: 50%;
        width: 19px;
        height: 17px;
        transform: translateY(-50%);
      }
    }

    &.btn-external-link {
      position: relative;
      padding: 10px 12px 8px 12px;
      font-family: "FuturaHeavy";

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        width: 37px;
        height: 37px;
        transform: translateY(-50%);
        background-image: url(/images/icon_link.png);
        background-size: contain;
      }
    }

    &.download,
    &.facebook,
    &.youtube,
    &.instagram {
      padding: 12px 12px 10px 12px;
      font-family: "FuturaHeavy";

      span {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          top: 50%;
          width: 14px;
          height: 14px;
          transform: translateY(-50%);
          left: -25px;
        }
      }
    }

    &.download {
      span:before {
        background-image: url('/images/icon_download_white.svg');
        top: calc(50% - 2px);
      }

      &.btn-transparent span:before,
      &:active span:before,
      &:focus span:before,
      &:hover span:before {
        background-image: url('/images/icon_download.svg');
      }
    }


    &.facebook {
      span:before {
        width: 21px;
        height: 24px;
        left: -40px;
        background-image: url('/images/icon_facebook_white.svg');
      }

      &.btn-transparent span:before,
      &:active span:before,
      &:focus span:before,
      &:hover span:before {
        background-image: url('/images/icon_facebook.svg');
      }
    }

    &.youtube {
      span:before {
        width: 25px;
        height: 20px;
        left: -40px;
        background-image: url('/images/icon_youtube_white.svg');
      }

      &.btn-transparent span:before,
      &:active span:before,
      &:focus span:before,
      &:hover span:before {
        background-image: url('/images/icon_youtube.svg');
      }
    }

    &.instagram {
      span:before {
        width: 23px;
        height: 26px;
        left: -40px;
        background-image: url('/images/icon_instagram_white.svg');
      }

      &.btn-transparent span:before,
      &:active span:before,
      &:focus span:before,
      &:hover span:before {
        background-image: url('/images/icon_instagram.svg');
      }
    }

    @media (max-width: $sm) {
      padding-left: 5px;
      padding-right: 15px;
    }

    &.itp-resources {
      padding-left: 30px;
      padding-right: 50px;
    }
  }

  // Primary Button CSS End

  .btn-info {
    text-transform: capitalize;
    min-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 31px;
    background-color: $Tangerine;
    border: none;
    max-width: 470px;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    padding: 20px 40px;
    transition: all 0.25s ease-in-out;

    &:hover,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      background-color: #b24534 !important;
    }

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background-color: $Tangerine !important;
    }

    @media (max-width: $sm) {

      &:hover,
      &:active {
        outline: none !important;
        box-shadow: none !important;
        background-color: $Tangerine !important;
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
        background-color: $Tangerine !important;
      }
    }

    @media (max-width: $lg) {
      font-size: 20px;
    }

    @media (max-width: $md) {
      font-size: 18px;
    }
  }

  // Cta Button CSS End

  // Secondary Button CSS End
  .btn-secondary {
    text-transform: uppercase;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 31px;
    background-color: $white;
    max-width: 470px;
    font-size: 18px;
    font-family: $BaseFontBold;
    padding: 15px 30px 14px;
    color: $LightBlue;
    border: 1px solid $Tangerine;
    transition: all 0.25s ease-in-out;
    line-height: 1;

    &:hover {
      outline: none !important;
      box-shadow: none !important;
      background-color: #b24534 !important;
      border-color: #b24534;
    }

    &:active,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background-color: $Tangerine !important;
      border-color: $Tangerine;
    }
  }

  .btn_bold {
    text-transform: uppercase;
    font-family: $BaseFontBold;
    max-width: 272px;
    min-width: 272px;
    padding: 20px;
    font-size: 18px;
    margin: 20px 0;
    transition: all 0.25s ease-in-out;
  }

  .btn-has-arrow {
    position: relative;

    &:after {
      content: url(/images/icon_white_arrow.svg);
      position: absolute;
      width: 16px;
      height: 16px;
      top: 15px;
      right: 20px;
    }

    &:hover:after {
      content: url(/images/icon_orange_arrow.svg);
    }
  }

  .btn-speaker {
    min-width: 44px;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 0;

    &:focus,
    &:active {
      background-color: #fff;
    }

    &:hover {
      background-color: #fff !important;

      img {
        content: url(/images/doptelet-pronunciation-speaker-button-orange.svg);
      }
    }

    &.mouseOver:hover {
      background-color: #fff !important;

      img {
        content: url(/images/doptelet-pronunciation-speaker-button-orange.svg);
      }
    }

    &:disabled {
      border-color: $Tangerine !important;
      background-color: $Tangerine !important;

      img {
        content: url(/images/doptelet-pronunciation-speaker-button-white.svg);
      }
    }

    &:active {
      border-color: $Tangerine !important;
    }

    &:disabled {
      opacity: 1;
    }

    img.speaker-icon {
      width: fit-content !important;
      height: auto !important;
    }

    @media (max-width: $sm ) {
      height: 36px;
      width: 36px;
      min-width: 36px;
      margin-top: -12px;

    }
  }

  .download-link {
    font-family: FuturaBold;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover {
      color: $Tangerine;
    }

    img {
      margin-right: 15px;
      max-width: 13px;
    }
  }
}
