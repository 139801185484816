@import "./variables.scss";

.video-section {
  scroll-margin-top: 150px;

  @media (max-width: $lg) {
    scroll-margin-top: 100px;
  }

  .container {
    padding-top: 60px;
    padding-bottom: 60px;

    @media (max-width: $lg) {
      padding-top: 35px;
      padding-bottom: 40px;
    }
  }

  .video-wrapper {
    position: relative;
    max-width: 880px;

    .video-thumb {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .video-responsive {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      margin-bottom: 10px;
      overflow: hidden;
      cursor: pointer;

      &:after {
        content: url(/images/play_button_D.svg);
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        pointer-events: none;

        @media screen and (max-width: 991px) {
          content: url(/images/play_button.svg);
          width: 47px;
          height: 47px;
        }
      }

      &.playing-video {
        &:after {
          display: none;
        }

        .video-thumb {
          display: none;
        }
      }
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .play-button {
      position: absolute;
      z-index: 1;
    }

    p {
      text-align: left;
      margin-bottom: 0;
    }
  }

  &.peach {
    background-color: $PeachyKeen;
  }

  .categories {
    gap: 10px;

    @media (max-width: $md) {
      max-width: 360px;
      justify-content: space-between;
      padding: 0 15px;
      border-bottom: none !important
    }

    .btn {
      border-radius: 3px;
      width: auto;
      text-transform: none;
      min-width: auto;
      max-width: auto;
      font-size: 10px;
      background: #eee;
      color: #888;
      padding: 8px 16px;

      &.active,
      &:focus,
      &:hover {
        background: #6a94d0 !important;
        color: #fff !important;
        border-color: #6a94d0 !important;
      }
    }

    &.mobile {
      @media (max-width:$md) {
        display: flex !important;
      }
    }

    &.desktop {
      @media (min-width:$md) {
        display: flex !important;
      }
    }
  }

  .video-list {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 347px;

    .col {
      border: 2px solid #fff;
    }

    .d-flex.col {
      align-items: center;
      cursor: pointer;
    }

    .active {
      border: 2px solid #6a94d0;
    }

    h4 {
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 15px;
    }

    @media screen and (max-width: $lg) {
      max-height: inherit;
    }
  }

  .transcript-container {
    .card-body {
      max-height: 280px;
      overflow-y: auto;
    }
  }

  .mobile-transcript {
    .card-body {
      max-height: 260px;
      overflow-y: auto;
    }
  }

  &.dopteller-diaries {

    h2,
    h3,
    p {
      margin-bottom: 25px;
    }

    @media (min-width: $md) {
      p {
        padding-right: 75px;
      }
    }

    .video-wrapper {
      margin-left: auto;
      max-width: 500px;
    }
  }
}
