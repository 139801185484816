@import "./variables.scss";

.financial .flex-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;

  @media (max-width: $lg) {
    flex-flow: row wrap;
    margin: 0;
    justify-content: center;
  }

  .content {
    &.full-width {
      max-width: 100%;
    }

    @media (max-width: $lg) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      order: 2;
    }
  }

  .img {
    display: flex;
    text-align: right;
    padding: 0;
    flex: 0 0 245px;

    @media (max-width: $lg) {
      order: 1;
      text-align: center;
      flex: 0 0 100%;
      padding-bottom: 40px;
      max-width: 245px;
    }
  }

  .btn.btn-primary {
    @media (max-width: $lg) {
      width: 100%;
    }
  }
}

.hero_header.assistance {
  .copy_header {
    max-width: 470px;
  }
}

.doptelet-connect-container {
  background: #fce7e3;

  img {
    max-width: 220px;
  }

  .step {
    .number-and-icon {
      align-items: center;

      span {
        font-family: $BaseFontBold;
        margin-right: 10px;

        &:after {
          content: '.';
        }
      }

      img {
        margin-right: 30px;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
}

.max-width-1024 {
  max-width: 1024px !important;
}

.break-410 {
  display: none;

  @media(max-width: 410px) {
    display: block;
  }
}

.doptelet-connect-resources {
  .col-lg-6 .cloud-blue-bg {
    padding: 30px 30px;

    .d-flex {
      margin-bottom: 30px;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
}
