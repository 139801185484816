@import "./variables.scss";

.drug-landing-hero h1 {
  font-size: 32px;
  line-height: 1.2;
}

@media screen and (max-width: $md) {
  .drug-landing-hero {
    .row > div:first-child {
      padding-top: 15px;
      h1 {
        font-size: 25px;
      }
    }
    img {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: $sm) {
  .drug-landing-hero {
    img {
      margin-top: 60px;
    }
  }
  .get-discussion-guide a {
    padding-left: 15px;
    padding-right: 15px;
  }
}
