@import "./variables.scss";

@import "./variables.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.4s all ease;
  z-index: 100;

  @media (max-width: $xl) {
    height: 80px;
    background-color: $white;
    display: flex;
    align-items: center;
    z-index: 100;
  }

  @media (max-width: $sm) {
    height: 70px !important;
  }

  .utility-nav {
    background-color: $NavyBlue;
    height: 40px;
    padding: 6px 0;
    transition: 0.5s all ease;

    @media (max-width: $xl) {
      position: absolute;
      right: 0;
      top: 100%;
      width: 45%;
      height: 100vh;
      background-color: $CloudBlue;
      padding: 30px 10px 100px;
      display: none;
    }

    @media (max-width: $md) {
      right: 0;
      width: 100%;
    }


    ul {
      display: flex;
      align-items: center;

      @media (max-width: $xl) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    li {
      margin-right: 32px;

      @media (max-width: $xl) {
        border-top: 2px solid $NavyBlue;
        margin: 0;
        width: 100%;
        padding: 15px 0;

        &:first-of-type {
          border-top: none;
        }
      }

      &:last-child {
        margin-right: 0;

        @media (max-width: $xl) {
          margin-left: auto;
        }

        @media (max-width: $xl) {
          border-top: none;
          padding-top: 0;
        }
      }
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: $white;

      &:hover {
        text-decoration: none;
      }

      @media (max-width: $xl) {
        flex-direction: column;
        font-size: 18px;
        font-weight: 500;
        color: $NavyBlue;
        text-align: left;
        display: block;
      }
    }

    .btn-light {
      color: $NavyBlue;
      width: 155px;
      height: 28px;
      outline: none;
      box-shadow: none;
      padding: 0 8px;
      display: flex;
      align-items: center;
      background-color: $white;
      border-radius: 4px;
      border: none;

      &:after {
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
        border-top: 0.4em solid $Tangerine;
        margin-left: auto;
      }

      &:focus {
        outline: none;
        box-shadow: none !important;
        border: none;
        background-color: $white !important;
      }

      @media (max-width: $xl) {
        height: 40px;
        border: 1px solid $NavyBlue;
        background-color: $white;
        border-radius: 4px;
        width: 100%;

        &:focus {
          border: 1px solid $white;
        }

        &:not(:disabled) {
          height: 40px;
        }
      }

    }

    .dropdown-toggle {
      &[aria-expanded="true"] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }


    .dropdown-menu {
      background-color: $white;
      border-radius: 4px;
      border-top-left-radius: 0;
      border: none;
      width: 300px;
      padding: 10px 15px;
      -webkit-box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      -moz-box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      transition: 0.4s all ease;

      @media (max-width: $xl) {
        border-top-right-radius: 0;
        border-top-left-radius: 4px;
      }
    }

    .dropdown {
      &.show {
        .dropdown-menu {
          transform: translate(0px, 25px) !important;

          @media (max-width: $xl) {
            transform: translate(-145px, 25px) !important;
          }

          @media (max-width: $xl) {
            transform: translate(0px, 40px) !important;
            width: 100%;
          }
        }
      }
    }

    .dropdown-item {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      color: $NavyBlue;
      transition: 0.4s all ease;
      margin-bottom: 12px;

      &:hover {
        font-family: $BaseFontBold;
      }

      @media(max-width: $xl) {
        margin-bottom: 0;
        padding: 10px 0;
      }
    }
  }

  .menu-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 1400px;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 90;
  }

  .default_logo_wrap {
    position: relative;
  }

  .logo_default {
    position: absolute;
    width: 203px;
    height: 80px;
    bottom: -90px;
    display: flex;
    flex-flow: row nowrap;

    @media (max-width: $xl) {
      position: static;
      height: 60px;
    }

    img {
      width: 203px;
      height: 80px;

      @media (max-width: $xl) {
        height: 60px;
      }

      &.default-logo {
        display: none;
        width: 142px;
        height: 56px;

        @media(max-width: $xl) {
          display: block !important;
        }
      }

      &.white-logo {
        @media(max-width: $xl) {
          display: none;
        }
      }
    }

    .speaker-button {
      display: block;
      display: flex;
      align-items: center;
      padding: 6px 24px 0;


      @media (max-width:$sm ) {
        padding: 0 8px 0 12px;
      }
    }


  }

  // Nav Open SCSS
  &.navOpen {
    .utility-nav {
      display: block;
      z-index: 100;
      padding-bottom: 100px;
    }

    .open-nav-overlay {
      width: 100%;
      height: 100vh;
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      z-index: 80;
      top: 100%;
      left: 0;
    }
  }
}

header.default-nav {
  position: relative;

  @media(max-width: $xl) {
    position: fixed;
  }

  .utility-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .menu-container {
    padding: 0 32px 0 10px;

    @media(max-width: $xl) {
      padding-left: 42px;
    }
  }

  .default_logo_wrap {
    position: absolute;
    top: 50px;

    @media (max-width: $xl) {
      position: relative;
      top: 0;
    }
  }

  // Nav Open SCSS
  &.navOpen {
    .utility-nav {
      position: absolute;
      top: 100%;
      display: block;
      z-index: 100;
      padding-bottom: 100px;

      @media(max-width: $xl) {
        width: 45%;
      }

      @media(max-width: $md) {
        width: 100%;
        right: 0;
      }
    }

    .open-nav-overlay {
      width: 100%;
      height: 100vh;
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      z-index: 80;
      top: 100%;
      left: 0;
    }
  }
}
