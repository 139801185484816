@import "./variables.scss";

.real-patient-stories .flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: center;
  @media (max-width: $lg) {
    flex-flow: row wrap;
    margin: 0;
  }
}
.real-patient-stories {
  .platey-mom {
    img {
      max-width: 100%;
    }
  }
}
