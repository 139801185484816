@import "./variables.scss";

.orange-box {
  border: 3px solid $Tangerine;
  padding: 15px 35px;

  @media screen and (max-width: $md) {
    padding: 25px;
  }
}

.florio-section {
  >.d-flex {
    gap: 40px;

    >img {
      max-width: 235px;
      height: 100%;
    }

    @media screen and (max-width: $md) {
      flex-direction: column;
      align-items: center;
      gap: 20px;

      >img {
        max-width: 135px;
        height: auto;
      }

      p a {
        display: block;
        text-align: center;
      }
    }
  }

  @media(max-width: $md) {
    padding: 40px 25px !important;
  }
}
