@import "./variables.scss";

.goal-getters .flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: center;

  @media (max-width: $lg) {
    flex-flow: row wrap;
    margin: 0;
  }

  .form-intro {
    margin-top: 60px;
    width: 275px;

    @media (max-width: $lg) {
      width: 100%;
    }

    img {
      max-width: 100%;
      margin-top: 40px;

      @media (max-width: $lg) {
        margin-top: 0;
        margin-bottom: 60px;
      }
    }
  }
}

.doptelet-goal-getters-signup {
  @media (max-width: $md) {
    .row {
      display: flex;
      flex-direction: column-reverse;
      gap: 40px;
    }
  }
}
