@import "./variables.scss";

.modal-modal {
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 900;
	display: none;
	position: fixed;
	overflow: hidden;
	top: 0;

	.overlay {
		position: absolute;
		width: 100vw;
		height: 100vh;
		z-index: 0;
	}

	.modal-container {
		width: 90%;
		max-width: 915px;
		position: fixed;
		background-color: $white;
		top: -100%;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 30px 0 40px 0;
		z-index: 1;

		.exitBtn {
			cursor: pointer;
			color: $NavyBlue;
			line-height: 40px;
			text-align: center;
			user-select: none;
			font-weight: 700;
			font-size: 20px;
			position: absolute;
			right: 10px;
			top: 0;
			height: 22px;
			width: 22px;
			transition: background-color 0.5s;

			img {
				height: 100%;
				width: 100%;
			}

			&.exitLink {
				position: static;
				right: auto;
				top: auto;
				color: $Tangerine;
				font-size: 18px;
				text-decoration: underline;
				font-weight: normal;
				line-height: 1.2;
				height: auto;
				width: auto;
			}
		}

		.content {
			width: 80%;
			margin: 0 auto;
			text-align: center;

			@media(max-width: 900px) {
				width: 90%;
			}

			img {
				margin: 30px auto;
			}

			p {
				color: $NavyBlue;
				font-size: 18px;
				line-height: 20px;
				padding-bottom: 20px;
			}

			.heading-2 {
				color: $NavyBlue;
				font-family: "FuturaBold";
				font-size: 28px;
				font-weight: 500;
				line-height: 1.2;
				max-width: 85%;
				margin: 0 auto;
				padding-bottom: 20px;

				@media(max-width: 900px) {
					max-width: 100%;
				}
			}

			.linkButton {
				margin-bottom: 1em;
			}

			@media screen and (max-width: 520px) {
				h2 {
					font-size: 24px;
					line-height: 30px;
				}

				p {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}
}

.fadeIn {
	animation: backgroundFadeIn 0.65s ease forwards;

	.modal-container {
		animation: fadeIn 0.65s ease-in-out forwards;
	}
}

@keyframes backgroundFadeIn {
	from {
		background-color: rgba(0, 0, 0, 0);
	}

	to {
		background-color: rgba(0, 0, 0, 0.7);
	}
}

@keyframes backgroundFadeOut {
	from {
		background-color: rgba(0, 0, 0, 0.7);
	}

	to {
		background-color: rgba(0, 0, 0, 0);
	}
}

@keyframes fadeIn {
	from {
		top: -100%;
	}

	to {
		top: 15%;
	}
}

.fadeOut {
	animation: backgroundFadeOut 0.45s ease forwards;

	.modal-container {
		animation: fadeOut 0.45s ease-in-out forwards;
	}
}

@keyframes fadeOut {
	from {
		top: 15%;
	}

	to {
		top: -100%;
	}
}

#terms-modal {
	display: none;

	&.fadeIn {
		display: block;
		top: 0;
	}

	.modal-container {
		width: 100%;
		height: calc(80% - 150px);
		max-height: 680px;
		max-width: 1026px;
		padding-top: 64px;

		@media(max-width: $md) {
			transform: translate(-50%, 5px);
			height: 80vh;
			max-width: calc(100vw - 40px);
			padding: 0;
		}

		.content {
			width: 100%;
			max-width: 900px;
			max-height: 555px;
			height: 100%;
			overflow: auto;
			// margin: 0 auto 0 80px;
			padding: 0 80px;
			text-align: left;

			@media (max-width: $md) {
				width: auto;
				height: 100%;
				max-height: 88%;
				margin: 40px 15px 30px 32px;
				padding: 0 20px 0 0;
			}

			.heading-2 {
				margin: 16px 0;
				padding-bottom: 0;
				line-height: 36px;

				@media(max-width: $md) {
					font-size: 28px;
					line-height: 36px;
				}

				+h3 {
					margin-top: 0;

					@media(max-width: $md) {
						font-size: 14px;
						line-height: 22px;
					}
				}
			}

			h3 {
				margin-top: 30px;
				font-size: 18px;
				font-family: $BaseFontBold;

				@media(max-width: $md) {
					font-size: 18px;
					line-height: 22px;
				}
			}

			ul {
				list-style: none;

				li {
					margin-bottom: 8px;
					margin-left: 20px;
					color: $SmokeGrey;
					font-size: 16px;

					@media (max-width: $md) {
						font-size: 14px;
						line-height: 21px;
						;
					}
				}
			}

			a {
				font-size: inherit;

				&:hover {
					color: $Tangerine;
				}
			}

			.small-caps {
				text-transform: uppercase;
				font-size: 13px;
			}
		}
	}
}

#phreesia-modal {
	display: none;

	&.fadeIn {
		display: block;

		.modal-container {
			padding-top: 50px;
			max-height: 75vh;
			overflow-y: scroll;
		}
	}
}


.modal-open {
	header.header {
		z-index: 1;
	}
}
