@import "./variables.scss";

.CLDHome {
  header .menu-container {
    max-width: 1270px;
  }

  body .container {
    max-width: 1270px;
  }

  .hero_header {
    .hero-img-mobile {
      display: none;
    }

    .hero_header_inner {
      h3 {
        font-size: 18px;

        button {
          margin-left: 30px;
        }
      }

      p {
        font-size: 12px;
        line-height: 15px;
      }
    }

    @media (min-width: 991px) {
      min-height: 555px;

      .hero_header_inner {
        max-width: 400px !important;
      }
    }

    @media (min-width: 600px) and (max-width: $lg) {
      background-image: url(/images/managing-itp-hero.jpg) !important;
      background-size: cover;
    }

    @media (max-width: $lg) {
      padding-top: 0 !important;

      .hero_header_inner {
        position: absolute;
        z-index: 1;
        background: transparent !important;
        color: white;
        padding-top: 40px !important;

        p {
          font-size: 12px;
        }
      }

      .hero-img-mobile {
        width: 100vw;

        img {
          max-height: 700px;
          object-fit: cover;
        }
      }
    }

    @media (max-width: 600px) {
      min-height: 720px;
      background-size: cover;
      background-position: 25% center;
    }
  }



  #about-doptelet {
    padding: 0 0 70px;

    p:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $lg) {
      padding: 40px 0;
    }

    @media (min-width: $md) {
      .col-md-6:first-child {
        padding-top: 70px;
      }
    }

    .mobile-img-wrapper {
      display: none;

      @media (max-width: $lg) {
        order: 2;
        display: block;
        padding-bottom: 20px;

        img {
          width: 100%;
        }
      }
    }

    h3 {
      &.margin-bottom {
        margin-bottom: 30px;
      }
    }

    h2 {
      margin-bottom: 20px;

      @media (max-width: $lg) {
        max-width: 700px !important;
      }
    }

    p {
      &.p-footnote {
        font-size: 14px;
        line-height: 24px;
      }

      @media(max-width: $md) {
        font-size: 20px;
        line-height: 35px;
      }
    }

    .img-wrapper {
      width: 100%;
      height: auto;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  #taking-doptelet-cld {
    background-color: $PeachyKeen;
    padding: 70px 0;

    @media (max-width: $md) {
      padding: 47px 0 50px;
    }

    p {
      color: $NavyBlue;

      &:last-child {
        margin-bottom: 0;
      }

      &.max-width-750 {
        max-width: 750px;
        margin: 0 auto 60px;

        @media (max-width: $lg) {
          margin: 0 auto 20px;
        }
      }

      &.circle-header {
        font-family: $BaseFontBold;
        margin-bottom: 30px;
      }

      &.size-18 {
        font-size: 18px;

        @media (max-width: $md) {
          font-size: 16px;
        }
      }

      &.max-width-840 {
        max-width: 840px !important;
      }

      @media (max-width: $md) {
        font-size: 14px;
        line-height: 27px;

        &.fs-16-md {
          font-size: 16px;
          margin-bottom: 0;
          margin-top: 20px;
        }
      }
    }

    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 75%;
      margin: 0 auto 30px;

      @media (max-width: $lg) {
        flex-flow: row wrap;
        width: 100%;
        margin: 0 auto;
      }

      li {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        flex: 0 0 29%;
        padding: 0;

        &:before {
          display: none;
        }

        .img-wrapper {
          flex: 0 0 100%;

          img {
            margin: 0 auto;
            width: 100%;
            height: auto;
            max-width: 160px;
          }
        }

        p {
          flex: 0 0 100%;
          padding-top: 30px;
          margin-bottom: 0;

          @media (max-width: $lg) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        @media (max-width: $lg) {
          flex: 0 0 100%;
        }

        @media (max-width: $md) {
          justify-content: center;
          margin-bottom: 30px;

          .img-wrapper {
            flex: 0 0 130px;
          }

          p {
            padding-top: 10px;
          }
        }
      }
    }

    .p-center-align {
      max-width: 916px;
      margin: 0 auto;
      margin-bottom: 48px;
    }

    .blue-wrapper {
      max-width: 75%;
      margin: 0 auto;
      background-color: $NavyBlue;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      padding: 40px 20px;

      @media (max-width: 1100px) {
        max-width: 85%;
      }

      @media (max-width: $lg) {
        max-width: 90%;
      }

      @media (max-width: $md) {
        max-width: calc(100% + 64px);
        margin-left: -32px;
        margin-right: -32px;
        border-radius: 0;
      }

      h3 {
        font-family: $BaseFontBold;
        color: $white;
        margin: 0 auto 20px;
        text-align: center;
        line-height: 32px;
      }

      p {
        color: $white;
        margin: 0 auto 30px;
        text-align: center;
        line-height: 24px;
      }

      .btn-primary {
        min-width: 300px;
        padding: 12px 0;

        @media (max-width: $md) {
          font-size: 15px;
        }
      }
    }
  }

  #dosing-guide {
    padding: 70px 0;

    @media (max-width: $md) {
      padding: 40px 0;
      margin-bottom: 0;
    }

    .btn {
      margin-top: 30px;
    }
  }

  .doptelet-connect-container {
    padding: 70px 0;

    @media screen and (max-width: $md) {
      padding: 60px 0;
    }

    .step {
      .copy {
        p {
          margin-bottom: 0;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &.step-2 {
        .copy p {
          @media (min-width: $md) {
            margin: 30px 0 20px;
          }
        }
      }
    }
  }

  .doptelet-connect-resource {
    padding-bottom: 60px;

    @media screen and (max-width: $md) {
      padding-bottom: 0;
    }

    .row .blue-bg {
      display: flex;
      height: 100%;
      padding: 30px;
      flex-direction: column;
      justify-content: space-between;
    }

    .col-md-6 {
      @media (max-width: $md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #copay-program {
    padding: 70px 0;

    @media (max-width: $lg) {
      padding: 40px 0 0;
    }

    @media (max-width: $md) {
      background-color: transparent;
    }

    .container {
      max-width: 1115px;

      .row {
        @media (max-width: $md) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .col-md-4,
      .col-md-6,
      .col-md-8 {
        @media (max-width: $md) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    p {
      color: $NavyBlue;
      font-size: 20px;
      font-weight: 500;

      .small-caps {
        font-size: 13px;
      }

      a:not(.btn) {
        font-size: inherit;
      }
    }

    .underline {
      text-decoration: underline;
    }

    .img-wrapper {
      width: 100%;
      height: auto;
      text-align: right;

      img {
        width: 100%;
        max-width: 277px;
      }

      @media (max-width: $lg) {
        text-align: center;
      }

      @media (max-width: $md) {
        margin-top: 40px;
      }
    }

    .col-md-6 {
      @media (max-width: $lg) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    .container {
      .row:first-child {
        h2 {
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 20px;
          color: $SmokeGrey;
          line-height: 36px;
        }

        .btn {
          margin-top: 10px;
        }
      }

      .row:last-child {
        .col-md-4 {
          @media (max-width: $md) {
            order: 2;
            margin-top: 20px;
          }
        }
      }
    }

    @media (max-width: $sm) {
      .nobreak-mobile {
        display: none;
      }
    }
  }

  #financial-assistance {
    padding: 70px 0;

    .img-wrapper {
      img {
        width: 100%;
        height: auto;
      }
    }

    .col-md-5 {
      max-width: 490px;
    }

    @media (max-width: $md) {
      padding: 60px 0;

      .d-flex {
        .col-md-3 {
          order: 2;
          margin-top: 20px;
        }

        .col-md-5 {
          order: 1;
        }
      }
    }
  }

  #got-questions {
    h2 {
      font-size: 32px;
      line-height: 42px;
    }

    .got-questions {
      padding: 70px 0;

      @media screen and (max-width: $md) {
        padding: 40px 0;
      }
    }

    .got-questions p {
      color: $SmokeGrey;
      max-width: 980px;
      margin-bottom: 0;
    }
  }

  #patient-resources {
    padding: 70px 0 30px;
  }

  @media(max-width: $md) {

    section.main-isi.isi .isi-bar-wrapper .isi-bar .bar_title,
    .isi-inline-wrapper .isi-content p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
