@import "./variables.scss";

.symptoms-itp {
  img {
    width: 100px;
  }
}

.get-updates {
  picture {
    display: block;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }
}

.learn-more-about-itp {
  img {
    max-width: 220px;
  }
}

.about-cta {
  .btn.btn-primary {
    @media(max-width:$lg) {
      width: 100%;
    }
  }
}
