@import "./variables.scss";

.hero_header {
  padding: 50px 0;
  z-index: 1;
  position: relative;
  min-height: 400px;

  h1 {
    font-size: 60px;
    line-height: 66px;
  }

  @media (min-width: $sidebarISICollapse + 1) {
    padding-top: 50px;
  }

  @media (max-width: $xl) {
    padding-top: 50px;
  }

  .container {
    padding: 0 15px;

    @media (max-width: $md) {
      padding: 0 22px;
    }
  }

  &.large-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;

    @media screen and (max-width: $lg) {
      padding-top: 20px;
      padding-bottom: 0;
      background-size: 100%;
      background-position: center;

      .container {
        padding: 0;
      }
    }

    @media (max-width: $sm) {
      background-position: bottom;
      background-size: cover;
    }
  }

  &.inner-bg {
    padding-top: 0;

    .hero_header_inner {
      padding: 40px 24px;
      background: $CloudBlue;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 13px 6px rgba(0, 0, 0, 0.15);

      @media screen and (max-width: $lg) {
        max-width: inherit !important;
        border-radius: 0;
        box-shadow: none;

        * {
          max-width: inherit !important;
        }
      }
    }
  }

  &.mobile-sm {
    @media(max-width: $lg) {
      min-height: 370px;

      .copy_header {
        max-width: 100%;
      }
    }

    @media(max-width: 600px) {
      .copy_header {
        max-width: 100%;
      }
    }
  }

  &.mobile-md {
    @media(max-width: $lg) {
      min-height: 450px;

      .copy_header {
        max-width: 100%;
      }
    }

    @media(max-width: 600px) {
      .copy_header {
        max-width: 100%;
      }
    }
  }

  @media (max-width: $lg) {
    min-height: 563px;
  }

  .hero_header_inner {
    max-width: 540px;

    >*:not(:first-child) {
      max-width: 470px;
    }

    ul {
      margin-bottom: 30px;

      li {
        margin-bottom: 10px;
      }
    }

  }

  .hero_header_title {
    margin-bottom: 10px;
    line-height: 0.85;

    &.extra-margin-top {
      margin-top: -20px;
    }

    &.extra-padding-bottom {
      padding-bottom: 30px;

      @media(max-width: $lg) {
        padding-bottom: 10px;
      }
    }

    @media(max-width: $md) {
      font-size: 50px;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
  }

  .copy_header {
    font-size: 24px;
    margin-bottom: 30px;
    line-height: 1.35;
    max-width: 536px;

    @media(max-width: $lg) {
      max-width: 60%;
    }

    @media(max-width: $md) {
      max-width: 75%;
    }

    @media(max-width: 600px) {
      font-size: 18px;
    }
  }

  .hero_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.desktop {
        display: block;

        @media (max-width: $md) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media (max-width: $md) {
          display: block;
        }
      }
    }
  }
}

.hero_halves {
  background: $CloudBlue;

  .row {
    >div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 50px;

      @media screen and (min-width: $lg) {
        max-width: 585px;
        padding-top: 120px;
        padding-bottom: 100px;
      }

      @media screen and (max-width: $lg) {
        padding: 30px 20px;
      }
    }
  }

  h1 {
    font-size: 60px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 991px) {
    img {
      height: 100%;
      width: auto;
      position: inherit;
    }
  }
}

.hero_text_only {
  background: $CloudBlue;
  padding: 30px 100px;

  p,
  h1 {
    font-weight: 500;
  }

  @media screen and (max-width: $sidebarISICollapse) {
    padding-top: 30px;
  }

  @media screen and (max-width: 1199px) {
    padding-top: 50px;
  }

  @media screen and (max-width: $md) {
    padding: 100px 30px 30px;
  }

  @media screen and (max-width: $sm) {
    padding-top: 30px;
  }
}
