@import "./variables.scss";

@import "./DefaultNavigation.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.25s all ease;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  z-index: 151;

  ul {
    li {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  @media (max-width: $xl) {
    height: 80px;
    background-color: $white;
    display: flex;
    align-items: center;
    z-index: 151;
  }

  .utility-nav {
    background-color: $NavyBlue;
    height: 40px;
    padding: 6px 0;
    transition: 0.5s all ease;

    @media (max-width: $xl) {
      position: absolute;
      right: 100%;
      bottom: 0;
      width: 45%;
      height: 100vh;
      background-color: $CloudBlue;
      padding: 30px 10px 100px;

      li.condition-selector {
        padding-top: 15px;
        border-top: 2px solid $NavyBlue !important;
      }
    }

    @media (max-width: $md) {
      right: 0;
      width: 100%;
    }

    li.social-link {
      margin-right: 20px;
    }

    a.utility-social-link {
      margin-left: 4px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media(max-width: $xl) {
        svg {
          path {
            fill: $NavyBlue;

            &#Path_200 {
              fill: $CloudBlue;
            }
          }
        }
      }

      img {
        margin-left: 7px;
      }
    }

    .menu_wrap-mobile {
      display: none;

      @media (max-width: $xl) {
        display: block;

        .cld-nav-mobile {
          li {
            &:last-of-type {
              padding-top: 15px;
              border-bottom: 2px solid $NavyBlue;
            }

            div {
              cursor: pointer;
              font-size: 18px;
              font-family: $BaseFontBold;
            }
          }
        }
      }

      >ul {

        // background: blue;
        >li {
          border-top: none;
          border-bottom: 2px solid $NavyBlue;
          order: 1;

          >a {
            font-family: $BaseFontBold;
          }

          >ul {
            // background: red;
            padding-left: 15px;
            padding-top: 5px;

            >li {
              border-top: none;
              padding: 10px 0 !important;

              a {
                font-size: 16px;
              }

              >ul {
                // background: green;
                padding-left: 15px;

                >li {
                  border-top: none;
                  padding: 10px 0 !important;
                }
              }
            }
          }

          a.display-bold {
            font-family: $BaseFontBold;

            &.has-submenu {
              padding: 5px 0 7px;
            }
          }

          // Hide find specialist button so remove border.
          &.buttonListItem {
            margin-top: 15px;
            border-bottom: none;

            button {
              width: 100%;
              max-width: inherit;

              @media (max-width: $sm) {

                &:hover,
                &:active,
                &:focus {
                  background: $Tangerine;
                  color: #fff;
                }
              }
            }
          }

          //ACTIVE NAV CLASSES
          a.is-active {
            text-decoration: none;
          }

          //FOR ACTIVE SUBMENUS
          &.active-submenu {
            span.submenu-link-btn {
              font-family: $BaseFontBold;

              svg {
                // color: #c92a39;
                // transform: rotate(180deg);
                transform-origin: 50% 45%;
                transition: transform 0.5s;
              }
            }
          }

          span {
            &.submenu-link-btn {
              font-size: 18px;
              font-weight: 500;
              display: block;

              @media(max-width: $md) {
                font-size: 20px;
              }

              svg {
                margin-left: 8px;

                @media (max-width: $xl) {
                  margin-right: 10px;
                  float: right;
                }
              }

              &.open {
                font-family: $BaseFontBold;

                svg {
                  transform: rotate(180deg);
                  transform-origin: 50% 45%;
                  transition: transform 0.5s;
                }
              }
            }
          }

          .sub_menu {
            display: block;

            li {
              border-bottom: none;
            }

            a {
              @media(max-width: $md) {
                color: $SmokeGrey;
              }
            }
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;

      @media (max-width: $xl) {
        flex-direction: column;
        align-items: flex-start;
      }

      &.utility-nav-wrapper {
        li {
          &:first-of-type {
            @media (max-width: $xl) {
              border-top: 2px solid $NavyBlue;
            }
          }
        }

        @media (max-width: $xl) {
          li.hcp-link {
            border-bottom: 2px solid $NavyBlue;
          }

          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center !important;

          .social-link {
            border-top: none;
            margin: inherit;
            flex: 0 0 50px;
            text-align: center;

            .utility-social-link {
              display: inline;
              margin-left: 0;
            }

            &:last-of-type {
              padding-top: 15px;
            }
          }
        }
      }

      &.error-utility-nav-wrapper {
        li {
          &:first-of-type {
            border-top: none;
          }
        }
      }
    }

    li {
      margin-right: 36px;

      @media (max-width: $xl) {
        // border-top: 2px solid $NavyBlue;
        margin: 0;
        width: 100%;
        padding: 15px 0;
      }

      &:last-child {
        margin-right: 0;

        @media (max-width: $xl) {
          margin-left: auto;
        }

        @media (max-width: $xl) {
          border-top: none;
          // padding-top: 0;
        }
      }
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: $white;

      &:hover {
        text-decoration: none;
      }

      @media (max-width: $xl) {
        flex-direction: column;
        font-size: 18px;
        font-weight: 500;
        color: $NavyBlue;
        text-align: left;
        display: block;
      }
    }

    .btn-light {
      color: $NavyBlue;
      width: 155px;
      height: 28px;
      outline: none;
      box-shadow: none;
      padding: 0 8px;
      display: flex;
      align-items: center;
      background-color: $white;
      border-radius: 4px;
      border: none;

      &:after {
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
        border-top: 0.4em solid $Tangerine;
        margin-left: auto;
      }

      &:focus {
        outline: none;
        box-shadow: none !important;
        border: none;
        background-color: $white !important;
      }

      @media (max-width: $xl) {
        height: 40px;
        border: 1px solid $NavyBlue;
        background-color: $white;
        border-radius: 4px;
        width: 100%;

        &:focus {
          border: 1px solid $white;
        }

        &:not(:disabled) {
          height: 40px;
        }
      }
    }

    .dropdown-menu {
      background-color: $white;
      border-radius: 4px;
      border-top-left-radius: 0;
      border: none;
      width: 300px;
      padding: 10px 15px;
      -webkit-box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      -moz-box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.2);
      transition: 0.25s all ease;

      @media (max-width: $xl) {
        border-top-right-radius: 0;
        border-top-left-radius: 4px;
      }
    }

    .dropdown {
      &.show {
        .dropdown-menu {
          transform: translate(0px, 25px) !important;

          @media (max-width: $xl) {
            transform: translate(-145px, 25px) !important;
          }

          @media (max-width: $xl) {
            transform: translate(0px, 40px) !important;
            width: 100%;
          }

          @media (max-width: $md) {
            padding: 10px;
            transform: none;
            position: relative !important;
            top: -40px !important;
            height: auto;
          }

          a {
            font-size: 18px !important;
          }
        }
      }
    }

    .dropdown-item {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      color: $NavyBlue;
      transition: 0.25s all ease;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        font-family: $BaseFontBold;
      }

      @media (max-width: $xl) {
        margin-bottom: 0;
        padding: 10px 0;
      }
    }

    #is-active {
      font-family: $BaseFontBold;
    }
  }

  .nav_wrap {
    padding: 10px 0;
    width: 100%;
    background-color: $white;
    z-index: 90;
    height: 106px;

    @media(max-width:$xl) {
      height: 80px;
    }

    @media(max-width:$sm) {
      height: 70px;
    }

    .menu-container {
      .align-items-center {
        width: 100%;
        margin: 0;
      }
    }

    .logo-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 !important;
      z-index: 1;

      a {
        display: block;
        box-sizing: border-box;

        img {
          max-width: 170px;
          margin-top: -12px;

          @media (max-width: $xl) {
            max-width: 140px;
          }

          @media(max-width: $sm) {
            max-width: 114px;
          }
        }
      }

      .speaker-button {
        display: block;
        display: flex;
        align-items: center;
        padding: 0 15px;

        @media (max-width: $sm) {
          padding: 12px 8px 0 12px;

          button:hover,
          button:focus,
          button:active {
            background-color: $Tangerine !important;

            img {
              content: url(/images/doptelet-pronunciation-speaker-button-white.svg);
            }
          }
        }
      }
    }

    .link-wrapper {
      @media (max-width: $xl) {
        display: none;
      }

      .menu_wrap {
        >ul {
          li a {
            display: inline-block;
            font-family: $BaseFontBold;
          }

          li {
            &.submenu-link {
              padding-top: 0;
              padding-bottom: 0;
            }

            &:hover {
              >a {
                border-bottom: 2px solid $NavyBlue;
              }

            }
          }
        }

        &.cld-menu {
          ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
              margin-right: 40px;
              border-bottom: 2px solid transparent;

              &:last-of-type {
                margin-right: 0;
              }

              &:hover {
                border-bottom: 2px solid $NavyBlue;
              }
            }
          }
        }

        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          >li {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            font-family: $BaseFontBold;
            font-size: 18px;
            line-height: 1;
            font-weight: 500;
            margin-right: 20px;
            white-space: nowrap;

            &.submenu-link a {
              padding-right: 20px;
            }

            &:last-child {
              margin-right: 0;

              &.buttonListItem {
                margin-top: -7px;
              }

              button {
                min-width: inherit;
              }
            }

            svg {
              position: absolute;
              right: 0;
              top: 0;
              margin-left: 3px;
              transform: rotate(0deg);
              transform-origin: 50% 45%;
              transition: transform 0.5s;
            }

            &:hover {

              .sub_menu {
                opacity: 1;
                z-index: 1;
                display: block;
                border-top: 2px solid $NavyBlue;

                .sub_sub_menu {
                  li a {
                    padding-top: 15px;
                    padding-left: 10px;
                  }

                  li:last-child a {
                    padding-bottom: 10px;
                  }
                }
              }

              a {
                text-decoration: none;
                white-space: nowrap;
              }
            }
          }
        }

        a {
          padding: 0 0 8px 0;
          font-size: 18px;
          color: $NavyBlue;
          width: 100%;
          border-bottom: 2px solid transparent;

          &:hover {
            text-decoration: none;
          }

          @media screen and (max-width: 1400px) {
            font-size: 16px;
          }

          @media screen and (max-width: 1320px) {
            font-size: 14px;
          }

          @media screen and (max-width: 1240px) {
            font-size: 13px;
          }
        }

        //ACTIVE NAV CLASSES
        .is-active {
          font-family: $BaseFontBold;
          text-decoration: none;
          border-color: $NavyBlue;
        }

        //FOR ACTIVE SUBMENUS
        .active-submenu {
          font-family: $BaseFontBold;
          // border-bottom: 2px solid $NavyBlue !important;

          svg {
            // color: #c92a39;
            // transform: rotate(180deg);
            transform-origin: 50% 45%;
            transition: transform 0.5s;
          }
        }

        .dropdown-toggle {
          background-color: transparent;
          padding: 0;
          border: none;

          &:hover,
          &:focus,
          &:active {
            outline: none !important;
            box-shadow: none !important;
            border: none !important;
            background-color: transparent !important;
          }

          &:focus {
            font-family: $BaseFontBold;
          }

          &:after {
            content: none;
          }
        }

        .dropdown-menu,
        .sub_menu {
          background-color: #ffffff;
          border: none;
          width: 300px;
          padding: 15px 15px;
          box-shadow: 0px 0px 4px 0px rgba(50, 50, 50, 0.2);
          transition: 0.25s all ease;

          .dropdown-item {
            padding: 0;
            margin-bottom: 10px;
          }
        }

        .sub_menu {
          position: absolute;
          top: calc(100% - 2px);
          flex-direction: column;
          opacity: 0;
          transition: 0.25s all ease;
          z-index: -1;
          text-align: left;
          display: none;

          >li {
            margin: 0 0 15px 0;
            padding: 0;
            align-items: flex-start;
            text-align: left;
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              font-family: $BaseFont;
              font-weight: 500;
              font-size: 16px;
              transition: 0.25s all ease-in-out;

              &.display-bold {
                font-family: $BaseFontBold;
              }
            }

            .is-active {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  // Nav Open SCSS
  &.navOpen {

    .nav_wrap,
    .menu-container {
      background: $CloudBlue;
    }

    .utility-nav {
      overflow-x: scroll;
      height: calc(100vh - 70px);
      z-index: 100;
      right: 0;
      padding-bottom: 150px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Landing page so hide main nav.
  &.landing-page {

    .menu_wrap,
    .menu_btn {
      display: none;
    }

    .get-the-guide {
      display: flex !important;
      align-items: center;
      justify-content: center;
      min-width: 130px;
      height: 40px;
      margin: 0;
      border-radius: 4px;
      cursor: pointer;
      background: $NavyBlue;
      color: #fff;

      @media (max-width: $xl) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Hide nav on mobile when scrolling.
@media (max-width: $md) {
  body.nav-tucked {
    .inner-content-wrap.sidebar-isi-push {
      margin-top: 65px;
    }
  }

  header {
    &.tucked {
      top: -80px;
    }
  }
}
