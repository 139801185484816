@import "./variables.scss";

#mini-isi {
  width: 100%;
  height: 220px;
  background: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 150;
  border-radius: 10px 10px 0 0;
  transition: all 0.25s ease-in-out;

  @media (max-width: $xl) {
    height: 210px;
  }

  @media (max-width: $lg) {
    height: 235px;
  }

  @media (max-width: $md) {
    height: 260px;
  }

  @media (max-width: $sm) {
    height: 300px;
  }

  @media (max-width: 400px) {
    height: 370px;
  }

  &.is-minimized,
  &.isi-minimized {
    height: 58px;

    .expansion_content {
      display: none;
    }

    .scroll-area {
      height: 0;
    }

    .full-isi-for-mobile {
      display: block;
    }
  }

  &.inline-isi-visible {
    top: 100vh;
  }

  @media screen and (min-width: $md) {
    // height: 240px;
    overflow: hidden;
  }

  @media screen and (max-width: $md) {
    // height: 290px;

    &.hide-mobile-isi-pronunciation {

      .scroll-area,
      .buttons-wrapper .minimize-icon {
        // display: none;
      }
    }
  }

  @media (max-width: $md) {
    .main-isi {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #F0F6FF !important;

      a:not(.btn):not(.dropdown-item) {
        text-decoration: underline;
      }
    }

    .full-isi-for-mobile {
      display: block !important;
      overflow: scroll;

      .container {
        max-width: 100vw;
        margin: 0;
        padding-top: 20px;
        background: $CloudBlue;
        overflow-x: hidden;
      }
    }

    &.expanded {
      bottom: 0;
      z-index: 1111;
      height: 100%;

      .expand_icon {
        display: none;
      }

      .minimize-icon {
        display: inherit !important;
      }

      .scroll-area,
      .buttons-wrapper .minimize-icon {
        display: none;
      }
    }
  }
}

section.main-isi.isi {
  background: $white;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;

  .container {
    @media (max-width: $md) {
      padding: 0 20px;
    }
  }

  .isi-bar-wrapper {
    background-color: $NavyBlue;
    padding: 16px 0;
    width: 100%;
    z-index: 100;
    border-radius: 10px 10px 0 0;

    @media (max-width: 600px) {
      padding: 10px 0;
    }

    .isi-bar {
      .bar_title {
        font-size: 18px;
        font-family: $BaseFontBold;
        line-height: 27px;
        color: $white;
        padding-right: 0;

        br {
          display: none;
        }

        @media (max-width: 600px) {
          font-size: 16px;
          line-height: 22px;

          br {
            display: block;
          }
        }

        @media (max-width: 356px) {
          br {
            display: none;
          }
        }
      }

      .buttons-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        padding-left: 0;

        .expand_icon {
          cursor: pointer;
          height: 30px;
          width: 30px;
          margin-right: 10px;

          img {
            height: 100%;
            width: 100%;
          }

          &.scrolled-open {
            .add_img {
              display: none;
            }

            .remove_img {
              display: block;
            }
          }

          .add_img {
            display: block;
          }

          .remove_img {
            display: none;
          }
        }

        .minimize-icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          display: none;

          @media (max-width: 600px) {
            height: 30px;
            width: 30px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .row {
        max-width: none !important;
      }

      @media (max-width: $md) {
        .container {
          padding-left: 20px;
          padding-right: 20px;

          >.row {
            max-width: inherit;
            margin-left: 0;
            margin-right: 0;
          }
        }

        .buttons-wrapper {
          padding: 0;

          .expand_icon {
            margin-right: 0;
          }
        }

        .bar_title {
          padding: 0;
          // white-space: nowrap;
        }
      }
    }

  }

  .scroll-area {
    height: 100%;
    background-color: $CloudBlue;

    .isi-wrapper {
      background-color: $CloudBlue;
      padding: 10px 0 0;

      strong {
        font-family: $BaseFontBold;
      }

      p {
        margin-bottom: 5px;
        font-weight: 500;
        line-height: 1.35;
      }

      ul,
      ol {
        padding: 0;
        margin: 0;
      }

      ul {
        &.padding-bottom {
          padding-bottom: 10px;
        }

        li {
          position: relative;
          padding-left: 20px;
          font-weight: 500;
          line-height: 1.35;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $NavyBlue;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 7px;
          }
        }
      }

      a {
        color: $Tangerine;
        text-decoration: underline;
        font-family: $BaseFontBold;

        &.light-font {
          font-weight: normal;
        }
      }

      .flex-list-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        max-width: 75%;

        @media (max-width: $md) {
          max-width: 100%;
        }

        @media (max-width: $sm) {
          flex-flow: row wrap;
          justify-content: flex-start;
        }

        .flex-list {
          flex: 0 0 45%;

          @media (max-width: $sm) {
            flex: 0 0 100%;
          }

          ul {
            li {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
