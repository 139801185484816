@import "./variables.scss";

footer {
  margin-bottom: 0;
  width: 100%;
  // &.sidebar-isi-push {
  //   max-width: calc(100vw - #{$sidebarISIWidth});
  //   @media screen and (max-width: 1500px) {
  //     .footer-container .footer-content .copyright-info p {
  //       padding-right: 150px;
  //       padding-left: 50px;
  //     }
  //   }
  //   @media screen and (max-width: $sidebarISICollapse) {
  //     max-width: inherit;
  //     .footer-container .footer-content .copyright-info p {
  //       padding-right: inherit;
  //       padding-left: inherit;
  //     }
  //   }
  // }
  .footer-container {
    padding: 30px 0 15px;
    background-color: $NavyBlue;
    flex-shrink: 0;

    @media (max-width: $md) {
      padding: 30px 0;
    }

    .footer-content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;

      @media (max-width: 1175px) {
        flex-flow: row wrap;
      }

      .footer_logo {
        flex: 0 0 215px;
        padding-right: 40px;

        @media (max-width: 1115px) {
          flex: 0 0 190px;
          padding-right: 20px;
        }

        @media (max-width: 1175px) {
          flex: 0 0 100%;
          padding-right: 0;
          padding-bottom: 20px;
          text-align: center;
        }
      }

      .footer_menu {
        flex: 0 0 40%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        @media (max-width: 1175px) {
          flex: 0 0 40%;
          align-items: center;
        }

        @media (max-width: 1175px) {
          flex: 0 0 100%;
          justify-content: center;
          padding-bottom: 20px;
        }

        @media (max-width: 600px) {
          flex-flow: row wrap;
          .social-links {
            justify-content: center;
          }
        }
        @media (max-width: $lg) {
          .social-links {
            margin-top: 20px;
            margin-bottom: 10px;
          }
        }

        .footer-link {
          flex: 0 0 auto;
          padding-right: 25px;

          &:last-of-type {
            padding-right: 0;
          }

          @media (max-width: 600px) {
            flex: 0 0 100%;
            padding-right: 0;
            padding-bottom: 20px;
            text-align: center;
            &:last-of-type {
              padding-bottom: 0;
            }
          }

          a {
            color: $white;
            text-decoration: none;
            font-weight: normal;

            @media (max-width: $lg) {
              font-size: 18px;
            }
          }

          &.social-link {
            @media (max-width: 1175px) {
              text-align: center;
            }

            @media (min-width: $lg) {
              margin: 10px 0;
            }

            img {
              margin-left: 6px;
              transform: translateY(-3px);
            }
          }
        }
      }

      .copyright-info {
        // min-width: 460px;
        flex: 0 0 470px;
        // margin-left: auto;

        @media (max-width: 1175px) {
          margin: 0 auto;
          flex: 0 0 100%;
        }

        p {
          color: $white;
          font-size: 16px;
          @media (max-width: 1175px) {
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}

.show-cookie-bar {
  footer {
    margin-bottom: 64px;

    @media (max-width: 768px) {
      margin-bottom: 101px;
    }

    @media (max-width: 610px) {
      margin-bottom: 123px;
    }

    @media (max-width: 501px) {
      margin-bottom: 145px;
    }

    @media (max-width: 384px) {
      margin-bottom: 167px;
    }
  }
}
