@font-face {
  font-family: 'Futura';
  src: url('../assets/fonts/FuturaBT-Book.woff2') format('woff2'),
    url('../assets/fonts/FuturaBT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Lt BT';
  src: url('../assets/fonts/FuturaBT-Light.woff2') format('woff2'),
    url('../assets/fonts/FuturaBT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaBold';
  src: url('../assets/fonts/FuturaStdBold.woff') format('woff'),
    url('../assets/fonts/FuturaStdBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaHeavy';
  src: url('../assets/fonts/FuturaStdHeavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaMedium';
  src: url('../assets/fonts/Futura-Medium.woff2') format('woff2'),
    url('../assets/fonts/Futura-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaCond-Md';
  src: url('../assets/fonts/FuturaCondensed-Medium.woff2') format('woff2'),
    url('../assets/fonts/FuturaCondensed-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'simpleaf';
  src: url('../assets/fonts/simpleaf.ttf') format('ttf'),
    url('../assets/fonts/simpleaf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
