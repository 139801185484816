@import "./variables.scss";

.faq-banner {
  img {
    height: 100%;

    @media (max-width:$lg) {
      height: 245px;
      margin: 0 auto;
      display: block;
    }
  }

  .row>div:first-child {
    padding-left: 0;
  }

}

.accordions {
  .container {
    margin: 0 auto;
    // max-width: 1270px;
  }
}

.accordion {
  >.card>.card-header {
    .btn-link {
      padding: 26px 40px;

      svg {
        right: 30px;
      }
    }
  }

  .card-body {
    margin: 30px 0;
    padding: 0 40px;
  }

  @media screen and (max-width:$md) {
    >.card>.card-header {
      .btn-link {
        padding: 15px 50px 15px 10px;
        line-height: 27px;

        svg {
          right: 10px;
        }
      }
    }

    .card-body {
      margin: 20px 0;
      padding: 0 12px;
    }
  }
}
