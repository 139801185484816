@import "./variables.scss";

.hero_header .hero_header_title {
  font-size: 80px;

  @media (max-width: $md) {
    font-size: 50px;
  }
}

.hero_header.about-doptelet {
  .hero_header_title {
    font-size: 80px;

    @media (max-width: $md) {
      font-size: 60px;
      line-height: 60px;
    }
  }

  .doptelet {
    display: inline-block;
    margin-top: 20px;
  }

  .btn-speaker {
    margin-top: -8px;
    margin-left: 14px;
  }
}


.lifeStyle_section {
  padding: 85px 0 50px 0;

  @media (max-width: $md) {
    padding: 50px 0;
  }

  @media (max-width: $md) {
    .row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;

      .col-md-6 {
        flex: 0 0 100%;
        padding-right: 0;

        &:first-of-type {
          order: 2;
        }
      }
    }
  }

  h2 {
    margin-bottom: 10px;
    line-height: 33px;

    @media (max-width: $md) {
      line-height: 27px;
    }
  }

  h3 {
    margin-bottom: 20px;
    line-height: 36px;

    @media (max-width: $md) {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 27px;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 500;
    }

    li:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #1b4677;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  .lifeStyle_img {
    box-sizing: border-box;

    img {
      height: auto;
      width: 100%;
    }
  }

  .join-us-cta {
    @media(max-width: $md) {
      margin-top: 80px;
      margin-bottom: 48px;

      .btn {
        margin: 0 auto;
        max-width: 210px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }
  }
}

.how {
  background-color: $PeachyKeen;
  padding: 80px 20px 0;

  @media (max-width: $md) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: $sm) {
    .col-md-12 {
      padding: 0;
    }
  }

  .container {
    @media (max-width: $md) {
      .row {
        margin: 0 auto;
      }
    }
  }

  .intro-copy {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;

    @media (max-width: $lg) {
      padding-bottom: 0;
    }

    @media (max-width: $md) {
      max-width: 100%;
    }

    p {
      font-size: 18px;

      &.extra-padding-bottom {
        padding-bottom: 30px;
      }

      @media (max-width: $lg) {
        margin-bottom: 0;
      }
    }

    h2 {
      margin-bottom: 10px;
      color: $LightBlue;
    }
  }

  .get-doptelet {
    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      max-width: 100%;
      margin: 0 auto 40px;

      @media (max-width: $lg) {
        flex-flow: row wrap;
      }

      li {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        flex: 0 0 33%;
        margin-bottom: 24px;

        @media (max-width: $lg) {
          flex: 0 0 100%;
        }

        @media (max-width: $sm) {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .img-wrapper {
          flex: 0 0 100%;
          flex: 0 0 100%;
          padding: 0 60px 20px;
          text-align: center;
        }

        h3 {
          margin: 0 auto 24px;
          max-width: 272px;
          font-family: $BaseFontBold;
          font-size: 18px;
          line-height: 25px;
          text-align: center;

          @media(max-width: $md) {
            font-size: 16px;
          }
        }

        p {
          flex: 0 0 100%;
          padding-top: 0;
          text-align: center;

          &.number {
            font-size: 24px;
            color: $Tangerine;
            font-family: $BaseFontBold;
            padding-top: 20px;

            @media (max-width:$md) {
              padding-top: 50px;
            }
          }

          &.no-padding-no-margin {
            margin-bottom: 0;
            padding-top: 0;
          }

          &.max-width {
            max-width: 75%;
            margin: 0 auto;

            @media (max-width: $lg) {
              max-width: 100%;
            }
          }

          &.max-width-sm {
            max-width: 272px;
            margin: 0 auto;

            @media (max-width: $lg) {
              max-width: 100%;
            }
          }

          &.max-width-md {
            max-width: 268px;
            margin: 0 auto 12px;

            @media(max-width:$lg) {
              max-width: 100%;
            }

          }

          &.appear-on-mobile {
            display: none;
          }

          @media (max-width: $sm) {
            &.disapear-on-mobile {
              display: none;
            }

            // &.number {
            //   margin-bottom: 0;
            // }
          }
        }

        span.appear-on-mobile {
          display: none;

          @media (max-width: $sm) {
            display: inline-block;
          }
        }
      }

      img {
        width: 142px;
      }
    }


    .eligibility {
      font-size: 14px;
      margin-top: 12px;
    }
  }

  @media screen and (max-width: $md) {
    .col {
      padding: 0;
    }

    .num {
      margin-top: 3rem !important;
    }
  }
}

.platey-picnic {
  img {
    width: 225px;

    @media(max-width:$lg) {
      width: 100%;
    }
  }
}

.dark-blue-bg {
  background-color: #144778;
  color: $white;

  @media(max-width:$lg) {
    margin-bottom: 60px;
  }
}

.about-doptelet {
  .patient-results {
    h2 {
      margin-bottom: 25px;
    }

    ul {
      li {
        margin-bottom: 20px;
      }
    }
  }

  .dop-side-effects {
    h2 {
      margin-bottom: 25px;
    }

    .img-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    ul {
      li {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .dop-serious-side-effects {
    h4 {
      margin-bottom: 25px;
    }

    .img-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    ul {
      margin-bottom: 40px;

      li {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.get-chronic-updates {
  margin-left: -30px;
  margin-right: -30px;
  padding: 40px 20px !important;
}

img.soft-serve {
  max-width: 480px;
}
