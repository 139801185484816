@import "./variables.scss";

.mobile-only {
  display: none !important;

  @media(max-width: $md) {
    display: flex !important;
  }
}

@media screen and (max-width: $md) {
  .home-hero-mobile {
    .row>div:first-child {
      padding-top: 15px;

      h1 {
        font-size: 55px;
      }
    }

    img {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: $sm) {
  .home-hero-mobile {
    a {
      padding-left: 15px;
      padding-right: 15px;
    }

    img {
      margin-top: 60px;
    }
  }
}

.itpHome .hero_header_inner {
  @media (max-width:$sm) {
    padding-top: 20px !important;

    p {
      font-size: 16px;
    }
  }
}

.itpHome .home-ctas {
  @media (min-width: $lg) {
    .d-flex {
      max-width: 360px;
      min-height: 125px;
    }
  }
}
