@import "./variables.scss";

.patient-resources {
  .hero_header {
    @media (max-width: $md) {
      min-height: 0;
    }
  }

  .cloud-blue-bg {
    .text-orange {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }

  .swings {
    @media(max-width: $md) {
      padding-left: 0;
      padding-right: 0;
    }

    .row {
      margin: 0;
    }
  }
}

#organizations {
  .col-lg-4 {
    img {
      height: 100px;
      width: auto;
    }

    h3 {
      font-size: 20px;
      font-family: 'FuturaBold';
      margin-bottom: 25px;
    }
  }
}
