@import "./variables.scss";

body {
	.col-md-6 {
		@media(max-width: $md) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.form_wrapper {

		.inputs .col-lg-6 {
			@media screen and (max-width: $lg) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&.hasErrors {
			.required-error {
				color: #C92A39;
			}

			@media (min-width: $lg) {

				// If the name field is blank, add margin to phone field
				.inputs .name-blank+.col-lg-6 {
					.form-group:first-child input {
						margin-bottom: 60px;
					}
				}
			}
		}

		p.required-fields {
			font-size: 18px;
			color: $SmokeGrey;
		}

		p.form_header {
			font-size: 18px;
			margin-bottom: 10px;
		}
	}

	form {
		@media(max-width: $lg) {
			width: 100%;

			>.row {
				margin-left: 0;

				.col-md-6 {
					max-width: 100%;
					flex: 100%;
					padding: 0;
				}
			}
		}

		.formDropdown {
			@media screen and (max-width: 450px) {
				.dropdown-toggle {
					white-space: normal !important;
					display: inline-block;
					text-align: left;
					height: auto !important;
					padding-top: 15px;
					padding-bottom: 15px;

					&:after {
						float: right;
						text-align: right;
						right: 10px;
						position: absolute;
						top: 40%;
					}
				}
			}

			button {
				font-family: $BaseFont;
				text-transform: none;
				font-weight: normal;
				line-height: 1;
				width: 100%;
				max-width: inherit;
				height: 40px;
				padding: 10px 20px;
				border: 2px solid #707070;
				border-radius: 4px;
				background-color: $white;
				color: $SmokeGrey;

				@media screen and (max-width: 450px) {
					padding: 10px 30px 10px 20px;
				}

				&.error {
					border-color: #c92a39;
					color: #c92a39;
				}

				&:after {
					border-right: .4em solid transparent;
					border-bottom: 0;
					border-left: .4em solid transparent;
					border-top: 0.4em solid $Tangerine;
					margin-left: auto;
				}

				&:hover,
				&:active,
				&:focus,
				&.active {
					background-color: $white !important;
					color: $SmokeGrey !important;
					border-color: #4d4d4d !important;
				}

				&.btn-primary {
					@media(max-width: $xl) {
						max-width: 100%;
					}
				}
			}

			.btn-primary:not(:disabled):not(.disabled).active,
			.btn-primary:not(:disabled):not(.disabled):active,
			&.show>.btn-primary.dropdown-toggle {
				background-color: $white !important;
				color: $Tangerine !important;
				border-color: $Tangerine !important;
			}

			.dropdown-menu {
				height: auto;
				max-height: 290px;
				overflow: auto;

				&.show {
					border: 2px solid #707070;
					width: 100%;
					background-color: $white;
					color: $Tangerine;
				}
			}

			.dropdown-item {
				white-space: normal !important;
				color: #4d4d4d;
				font-family: Futura;
				font-size: 18px;
				font-weight: 500;
				font-style: normal;
				letter-spacing: normal;
				line-height: 27px;
				text-align: left;
				padding: 10px 20px;

				&.active,
				&:active,
				&:focus,
				&:focus-within {
					background-color: $white !important;
					color: $SmokeGrey !important;
				}

				&.hide {
					display: none;
				}

				&:hover {
					color: $Tangerine;
					background-color: $white;
				}
			}
		}

		.form-label {
			color: #1b4677;
			font-family: Futura;
			font-size: 18px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 27px;
			text-align: left;
			margin-bottom: 6px;
		}

		.form-group {
			margin-bottom: 35px;

			&.half-size {
				width: 45%;
				display: inline-block;

				&:first-of-type {
					padding-right: 20px;
				}
			}

			input {
				width: 100%;
				font-size: 18px;
				color: $SmokeGrey;
				line-height: 1;
				padding: 10px 20px;
				height: 40px;
				border: 2px solid #707070;
				border-radius: 4px;

				&.error {
					border-color: #c92a39;

					&::placeholder {
						color: #c92a39;
					}
				}

				&::placeholder {
					color: $SmokeGrey;
					opacity: 0.5;
				}

				&:focus {
					outline: none !important;
					border: 1px solid $Tangerine;
					box-shadow: 0 0 0 0.2rem rgba(224, 90, 68, .25);
				}

				&.state-filter {
					border: none;
					background: transparent;
					padding: 0 1.5rem 0.5rem;
					margin-bottom: .25rem;
					box-shadow: none;
					opacity: 0.8;
					font-size: 14px;
					height: 32px;
				}
			}

			&.error .form-label {
				color: #c92a39;
			}
		}

		.email-group {
			position: relative;
		}

		.email-input {
			position: absolute;
			top: 35px;

			&:before {
				content: url(/images/email.svg);
				position: absolute;
				width: 20px;
				height: 15px;
				top: 9px;
				left: 10px;
			}

			input {
				padding-left: 40px;
			}
		}

		input#email {
			padding-left: 35px;
		}

		input[type="checkbox"] {
			display: none;

			+*::before {
				content: "";
				display: flex;
				vertical-align: bottom;
				min-width: 40px;
				min-height: 40px;
				height: 40px;
				border-radius: 4px;
				border: 2px solid #707070;
				margin-right: 22px;
				margin-top: 5px;
			}

			&.error+*::before {
				border-color: #c92a39;
			}
		}

		input[type="checkbox"]:checked {
			+*::before {
				background: transparent;
				border: 2px solid #707070;
				min-width: 40px;
				min-height: 40px;
				margin-right: 22px;
				margin-top: 5px;
			}

			+*::after {
				content: "";
				display: block;
				width: 28px;
				height: 28px;
				position: absolute;
				background: $Tangerine;
				position: absolute;
				border-radius: 4px;
				left: 6px;
				margin-top: 6px;
			}
		}

		input[type="checkbox"] {
			+label {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-content: flex-start;

				cursor: pointer;

				@media (min-width:$md) {
					align-items: center;
				}
			}
		}

		.form-check {
			padding-top: 15px;
			padding-left: 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: center;

			@media (max-width: $md) {
				padding-top: 25px;
			}

			.form-check-label {
				font-size: 18px;

				span {
					a {
						text-decoration: underline;
						color: $Tangerine;
						font-family: FuturaBold;
						font-size: inherit;

						&:hover,
						&:focus,
						&:active {
							color: $Tangerine;
							outline: none !important;
						}
					}
				}
			}

			&.error {
				span {
					color: #c92a39;

					a {
						color: #c92a39;
					}
				}
			}

			.invalid-feedback {
				margin: 1rem 0 0.25em 2px;
			}
		}

		.submit-btn {
			margin-top: 60px;

			@media (max-width: $md) {
				margin-top: 40px;
			}
		}

		.invalid-feedback {
			color: #C92A39;
			display: none;
			margin: 0.5rem 0 -0.5rem 2px;
			font-size: 18px;
			font-weight: 500;
			font-family: FuturaBold;

			&.submit-error {
				font-size: 18px;
				margin-left: 1rem;
			}
		}

		.show {
			display: block !important;
		}

		p small {
			margin-top: 10px;
			line-height: 1.5;
			display: block;
		}
	}

	.show-address-two {
		margin-bottom: 35px;
		color: #df5a42;
		font-family: Futura;
		font-size: 18px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 22px;
		text-align: left;
		display: block;
	}
}

.grecaptcha-badge {
	bottom: 130px !important;
	z-index: 1000;
}

@media screen and (max-width: 400px) {
	body {
		.container.sign-up {
			padding: 0 32px !important;

			.row {
				margin: 0 auto;
			}

			.col-xl,
			.col-xl-6 {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}

#sign-up {
	.inputs .col-lg-6 {
		&:first-child {
			padding-left: 0;
		}
	}
}
