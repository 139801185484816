@import "./variables.scss";

.hero_halves.talk-to-your-doctor {
  img {
    object-fit: contain;
  }
}

.not-sure {
  .question-mark {
    width: 100px;
  }
  @media (max-width: $md) {
    .question-section {
      flex-direction: column;
    }
  }
  .platey-reads {
    width: 204px;
    margin: 20px;
    @media (max-width: $md) {
      width: 314px;
    }
  }
  .free-hugs {
    width: 239px;
    margin: 20px;
    @media (max-width: $md) {
      width: 314px;
    }
  }
}

.setting_treatment_section {
  ul {
    margin-bottom: 40px;
    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.blue-bg {
  background-color: #f0f6ff;
}
