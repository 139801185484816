@import "./variables.scss";

.no-gutters {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%;

  @media (max-width: $lg) {
    flex-flow: row wrap;
  }

  .col {
    flex: 0 0 50%;

    @media (max-width: $lg) {
      flex: 0 0 100%;
    }
  }
}

.cards_wrap {
  @media(max-width: $md) {
    .row>.col:first-child {
      border-bottom: 2px solid $NavyBlue;
    }
  }
}

.custom_card {
  padding: 40px 0;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @media(max-width: $lg) {
    justify-content: center;
    padding: 0;
  }

  @media(max-width: $sm) {
    justify-content: stretch;
  }

  &.bg-white {
    justify-content: flex-end;

    @media(max-width: $lg) {
      justify-content: center;
    }
  }

  .card_inner {
    max-width: 650px;
    padding: 15px 50px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;

    @media(max-width: $lg) {
      padding: 35px 30px;
      width: 100%;
    }

    &.border-after {
      &:after {
        @media(min-width: $lg) {
          content: '';
          position: absolute;
          right: 0;
          display: block;
          width: 2px;
          background: $NavyBlue;
          height: 137px;
        }
      }
    }
  }

  h3 {
    font-size: 24px;
    font-family: $BaseFontBold;
    line-height: 32px;
    margin-bottom: 30px;

    @media(max-width:$md) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .img-wrapper {
    flex: 0 0 25%;
    text-align: right;

    img {
      height: auto;
      width: auto;
      max-width: 120px;
      object-fit: contain;
    }

    @media(max-width: $md) {
      text-align: center;

      img {
        width: 100%;
        max-width: 90px;
      }
    }
  }

  .card_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 75%;

    @media (max-width: $md) {
      padding-right: 20px;
    }

    &.extra-padding-bottom-mobile {
      h3 {
        @media(max-width: 1273px) {
          padding-bottom: 20px;
        }

        @media(max-width: $lg) {
          padding-bottom: 0;
        }

        @media(max-width: 415px) {
          max-width: 185px;
        }
      }
    }
  }

  .btn {
    margin-top: auto;

    @media(max-width: $md) {
      min-width: 200px;
    }
  }
}

.page-cta-link {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  text-decoration: none !important;
  color: inherit;

  .d-flex {
    margin-bottom: 20px;
    gap: 18px;

    img {
      width: 90px;
      height: 90px;
    }
  }

  p {
    flex: 1;
    font-size: 18px;
  }

  &:hover {
    outline: 2px solid $Tangerine;
    border-radius: 4px;

    p {
      color: $NavyBlue;
    }
  }
}

.bottom-page-ctas {

  .container-fluid {
    .row {
      >div {
        margin: 0 !important;
      }

      >div .content {
        padding: 60px 20px;
      }
    }

    @media (min-width: $md) {
      .row {
        >div .content {
          padding: 60px 0;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        >div:first-child {
          padding-right: 80px;
          align-items: flex-end;

          .content {
            max-width: 540px;
          }
        }

        >div:last-child {
          padding-left: 60px;
          align-items: flex-start;

          .content {
            max-width: 560px;
          }
        }
      }
    }
  }

  img {
    display: block;
    height: 100%;
    max-height: 220px;
    min-height: 220px;
    width: auto;
    margin: 0 auto 20px;

    @media (max-width:$md) {
      width: 100%;
    }
  }

  .btn-primary {
    max-width: 315px;
  }

  .tammy {
    img {
      height: auto;
      max-height: 150px;
      min-height: 150px;
    }

    @media (max-width:$md) {
      h3 {
        font-size: 18px;
      }

      img {
        min-height: 100px;
        max-height: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
