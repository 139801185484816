@import "./variables.scss";

.accordions {
  margin: 35px 0 35px 0;

  @media (min-width: $lg) {
    margin: 40px 0 40px 0;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 20px;

    @media (min-width: $lg) {
      margin-bottom: 30px;
    }
  }
}
