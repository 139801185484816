@import "./variables.scss";

.error-section {
  
  .flex-error {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    max-width: 1440px;
    margin: 0 auto;

    min-height: calc(100vh - 70px);

    @media (max-width: $sm) {
      flex-flow: row wrap;
  

    }
    .flex-error-item {
      flex: 0 0 50%;
      padding: 80px 20px 150px 100px;

      @media(max-width:1400px) {
        padding-left: 80px;
      }
      @media(max-width:1350px) {
        padding-left: 60px;
      }
      @media(max-width:1300px) {
        padding-left: 30px;
      }
      @media(max-width:1250px) {
        padding-left: 12px;
      }
      @media(max-width:1200px) {
        padding-left: 20px;
      }
      @media (max-width: $sm) {
        flex: 0 0 100%;
        order: 2;
        padding-bottom: 80px;
      }
      &.with-bg {
        padding: 20px;
        height: 750px;
        background-image: url("../assets/images/404/doptelet-platelet-character-jogging-park.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @media (max-width: $xl) {
          height: 650px;
        }
        @media (max-width: $sm) {
          order: 1;
          background-size: cover;
          background-image: url("../assets/images/404/doptelet-platelet-character-jogging-park.jpg");
          height: 500px;
          background-position: top;
        }
        @media (max-width: 375px) {
          height: 440px;
        }
      }
      h1 {
        padding-bottom: 20px;
        line-height: 80px;
        &.extra-large {
          padding-top: 80px;
          font-size: 230px;
          text-align: center;
          color:$white;
          @media (max-width: $sm) {
            font-size: 100px;
            padding: 0;
          }
        }
        &.mobile-only {
          display: none;
          line-height: 1em;
          @media (max-width: $sm) {
            display: block;
            font-size: 45px;
            color:$white;
          }
        }
        &.hide-on-mobile {
          max-width: 450px;
          @media (max-width: $sm) {
            display: none;
          }
        }
      }
      p {
        font-size: 24px;
        max-width: 370px;
        padding-bottom: 20px;
        @media (max-width: $sm) {
          max-width: 100%;
        }
      }
      .btn-wrapper {
        @media (max-width: $sm) {
          text-align: center;
        }
      }
    }
  }
}
