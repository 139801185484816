@import "./variables.scss";

.doptelet-share-your-story {
  margin-top: 60px;

  .form_wrapper {
    margin-top: 20px;
    &.hasErrors .required-error {
      color: #C92A39;
    }
  }

  .recruitment-form {
    .row.inputs {
      >.col-lg-6 {
        @media (max-width: $md) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .agree-to-terms {
      @media (max-width: $md) {
        padding-top: 0;
      }
    }
  }
}

.bottom-page-ctas {
  @media (max-width: $md) {
    img {
      object-fit: contain;
    }

    .cloud-blue-bg {
      margin-left: -15px;
      margin-right: -15px;
      max-width: 100vw;
      flex-basis: 100vw;
    }
  }
}

.share-your-story .flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: center;

  @media (max-width: $lg) {
    flex-flow: row wrap;
    margin: 0;
  }

  .form-intro {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 60px;
    width: 330px;

    @media (max-width: $lg) {
      width: 100%;
    }

    picture {
      align-self: center;
    }

    img {
      max-width: 100%;
      margin-top: 40px;

      @media (max-width: $lg) {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }

  .recruitment-form {
    form {
      width: 505px;

      @media (max-width: $lg) {
        width: 100%;
      }
    }

    input {
      height: 44px;
    }
  }
}
