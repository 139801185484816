@import "./variables.scss";

.scroll_btn {
	width: 50px;
	height: 50px;

	position: fixed;
	right: 25px;
	bottom: 25px;
	cursor: pointer;
	z-index: 80;
	display: none;
	&.show {
		display: block;
    display: none; // Agency had us hide the back to top.
	}
	img {
		width: 50px;
		height: 50px;
	}
}

.show-cookie-bar {
	.scroll_btn {
		bottom: 185px;
	}
}
