@import "./variables.scss";

$gray-border: #707070;

.accordion {
  margin-bottom: 15px;

  >.card {
    border: 1px solid $gray-border;
    border-radius: 0px;

    >.card-header {
      padding: 0;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid $gray-border;

      .btn-link {
        position: relative;
        width: 100%;
        padding: 16px 30px 16px 20px;
        text-align: left;
        font-size: 20px;
        font-family: $BaseFontBold;
        color: $NavyBlue;
        line-height: 1.1;
        text-decoration: none;
        display: flex;
        z-index: 80;
        justify-content: space-between;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        svg {
          color: $Tangerine;
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }

        @media (max-width: $md) {
          color: $NavyBlue;
          font-size: 20px;

        }
      }
    }
  }

  .card-body {
    padding: 0 40px 0 20px;
    margin: 20px 0;

    p {
      font-size: 18px;
    }

    b {
      font-family: "FuturaBold";
    }

    li {
      margin-bottom: 16px;
    }
  }
}
