/* Menu btn CSS Start */
.menu_btn {
  min-width: 100px;
  height: 40px;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  display: none;
  background: $NavyBlue;
  @media (max-width: $xl) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  div {
    color: #fff;
    &:last-of-type {
      display: none;
    }
  }
  .bars {
    display: block;
    span {
      position: relative;
      display: block;
      margin-right: 8px;
      height: 2px;
      width: 13px;
      background-color: #fff;
      transition: all 0.15s;
      transform-origin: left center;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }
}

header {
  &.navOpen {
    .menu_btn {
      div {
        &:first-of-type {
          display: none;
        }
        &:last-of-type {
          display: inherit;
        }
      }
      .bars {
        margin-top: 3px;
        span:first-child {
          top: -1px;
          left: 1px;
          transform: rotate(45deg);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:last-child {
          top: -2px;
          left: 1px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
/* Menu btn CSS End */
