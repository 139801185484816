@import "./variables.scss";

.isi-inline-itp {
  position: relative;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.15);
  z-index: 1;

  .isi-content {
    background: #fff;
    font-size: 16px;

    @media(max-width: $sm) {
      max-width: 100vw;
      overflow-x: hidden;
    }


    p {
      font-size: 16px;
      line-height: 20px;

      @media (max-width: $md) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    ul {
      font-size: 16px;
      margin-left: 20px;

      @media (max-width: $md) {
        font-size: 18px;
      }

      li {
        margin-bottom: 10px;
      }
    }
  }

  .inline-isi-header {
    color: #fff;
    background: $NavyBlue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px 0;
    margin-bottom: 20px;

    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      line-height: 22px;
    }

    @media screen and (max-width: $md) {
      padding: 10px 0;

      .container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  @media screen and (max-width: $md) {
    .isi-content {
      padding-top: 0 !important;

      >.container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.isi-bar-wrapper {
  background-color: $NavyBlue;
  padding: 8px 0;
  width: 100%;
  z-index: 100;
  border-radius: 10px 10px 0 0;

  @media (max-width: 675px) {
    padding: 10px 0;
  }

  .isi-bar {
    .bar_title {
      font-size: 16px;
      font-family: $BaseFontBold;
      color: $white;

      br {
        display: none;
      }

      @media (max-width: 675px) {
        font-size: 14px;
        line-height: 16px;

        br {
          display: block;
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      align-content: center;

      .expand_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 10px;

        @media (max-width: 675px) {
          height: 30px;
          width: 30px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        &.scrolled-open {
          .add_img {
            display: none;
          }

          .remove_img {
            display: block;
          }
        }

        .add_img {
          display: block;
        }

        .remove_img {
          display: none;
        }
      }

      .minimize-icon {
        cursor: pointer;
        width: 24px;
        height: 24px;

        &.is-minimized {
          display: none;
        }

        @media (max-width: 675px) {
          height: 30px;
          width: 30px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .minimize {
          display: block;
        }
      }
    }
  }
}

.isi-inline-wrapper {
  .isi-content {
    padding: 0 0 20px;
    background-color: $CloudBlue;

    strong {
      font-family: $BaseFontBold;
      font-weight: normal;
    }

    p {
      margin-bottom: 12px;

      &.no-padding-bottom {
        margin-bottom: 0;
      }

      &.list-header {
        margin-bottom: 10px;
      }

      @media (max-width: $lg) {
        margin-bottom: 6px;
      }
    }

    ul,
    ol {
      // padding: 0;
      // margin: 0;
    }

    ul {
      &.padding-bottom {
        padding-bottom: 10px;
      }

      li {
        position: relative;
        padding-left: 20px;
        font-weight: 500;
        line-height: 1.35;

        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $NavyBlue;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 7px;
        }
      }
    }

    a {
      color: $Tangerine;
      text-decoration: underline;
      font-family: $BaseFontBold;

      &.light-font {
        font-family: $BaseFont;
      }
    }
  }
}

.main-isi,
.isi-content {
  li.bullet-circle {
    &:before {
      background-color: transparent !important;
      border: 1px solid $NavyBlue;
    }
  }
}

.main-isi {}

.flex-list-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  max-width: 85%;

  @media (max-width: $md) {
    max-width: 100%;
  }

  @media (max-width: $sm) {
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .flex-list {
    flex: 0 0 45%;

    @media (max-width: $sm) {
      flex: 0 0 100%;
    }

    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }
}
